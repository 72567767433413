export const ROUTE_PATHS = {
  LAYOUT: "",
  NEWS: "news",
  SHOWCASES: "showcases",
  SUBMIT_TESTIMONIAL: "testimonial",
  NOT_FOUND: "**",
  NOT_FOUND_ANY: "**",
  SEARCH_RESULTS: "search-results",
  CONFIG: "config",
  LANDING: "landing-page"
};

export const AUTH_ROUTE_PATHS = {
  SIGN_IN_REDIRECT: "sign-in",
  SIGN_OUT_REDIRECT: "sign-out"
};
